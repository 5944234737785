import { TRPCClientError } from '@trpc/client'
import type { inferRouterOutputs } from '@trpc/server'
import type { AppRouter } from '@/server/trpc/routers'

type RouterOutput = inferRouterOutputs<AppRouter>
type getCategoriesOutput = RouterOutput['sites']['getCategories']

type ErrorOutput = TRPCClientError<AppRouter>

export default function useGetQualities () {
  const { $client } = useNuxtApp()
  return useAsyncData<getCategoriesOutput, ErrorOutput>(() => $client.sites.getCategories.query())
}

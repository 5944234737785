import { TRPCClientError } from '@trpc/client'
import type { inferRouterOutputs } from '@trpc/server'
import type { AppRouter } from '@/server/trpc/routers'

type RouterOutput = inferRouterOutputs<AppRouter>
type getQualitiesOutput = RouterOutput['sites']['getQualities']

type ErrorOutput = TRPCClientError<AppRouter>

export default function useGetQualities () {
  const { $client } = useNuxtApp()
  return useAsyncData<getQualitiesOutput, ErrorOutput>(() => $client.sites.getQualities.query())
}

import { TRPCClientError } from '@trpc/client'
import type { inferRouterOutputs } from '@trpc/server'
import type { AppRouter } from '@/server/trpc/routers'

type RouterOutput = inferRouterOutputs<AppRouter>
type getOriginalTapesOutput = RouterOutput['sites']['getOriginalTapes']

type ErrorOutput = TRPCClientError<AppRouter>

export default function useGetQualities () {
  const { $client } = useNuxtApp()
  return useAsyncData<getOriginalTapesOutput, ErrorOutput>(() => $client.sites.getOriginalTapes.query())
}

<template>
  <section class="bg-white dark:bg-gray-900">
    <div class="container px-6 py-2 mx-auto">
      <div class="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-12 md:grid-cols-2 xl:grid-cols-3">
        <CategoryBox
          v-for="type in typesData"
          :key="type.privateLabel"
          :title="$t(type.privateLabel)"
          :sites="getSitesByType(type.privateLabel)"
        />
        <CategoryBox
          v-for="category in categoriesData"
          :key="category.privateLabel"
          :title="$t(category.privateLabel)"
          :sites="getSitesByCategory(category.privateLabel)"
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { useSiteStore } from '~~/stores/sites'
import { useGetTypes, useGetCategories } from '~/composables'

const siteStore = useSiteStore()
const { sites } = siteStore

const { data: typesData } = await useGetTypes()
const { data: categoriesData } = await useGetCategories()

const getSitesByType = (type: string) => {
  return sites.filter((site) => {
    return site.types.some((siteType: { type: { privateLabel: string } }) => siteType.type.privateLabel === type)
  })
}

const getSitesByCategory = (category: string) => {
  return sites.filter((site) => {
    return site.categories.some((siteCategory: { category: { privateLabel: string } }) => siteCategory.category.privateLabel === category)
  })
}

</script>

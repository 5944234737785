<template>
  <div class="dark:bg-gray-900">
    <div
      class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-8"
    >
      <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-4">
        <div>
          <p class="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-blue-100">
            {{ $t('films_gratis') }}
          </p>
        </div>
        <h2 class="max-w-lg mb-6 dark:text-gray-300 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
          <span class="relative inline-block">
            <svg
              class="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
              fill="currentColor"
              viewBox="0 0 52 24"
            >
              <defs>
                <pattern id="34f481be-159a-4846-821d-9ca19fb6bcc5" height=".30" width=".135" x="0" y="0">
                  <circle cx="1" cy="1" r=".7" />
                </pattern>
              </defs>
              <rect fill="url(#34f481be-159a-4846-821d-9ca19fb6bcc5)" height="24" width="52" />
            </svg>
            <span class="relative">{{ $t('la') }}</span>
          </span>
          {{ $t('maniere_la_plus_rapide_pour_trouver_des_films_gratuits') }}
        </h2>
        <p class="text-base dark:text-gray-300 text-gray-700 md:text-lg">
          {{ $t('une_liste_actualisee') }}
        </p>
      </div>

      <ul class="flex border-4 dark:border-none">
        <li class="flex-1 mr-2 table">
          <a id="tabComparator" class="text-center table-cell align-middle block border border-white rounded dark:text-gray-300 py-2 px-4 text-blue-500 hover:bg-gray-200" href="#" @click="changeTab(Comparator)">{{ $t('comparateur') }}</a>
        </li>
        <li class="flex-1 mr-2 table">
          <a id="tabRanking" class="text-center table-cell align-middle block border border-blue-500 rounded dark:text-gray-300 bg-blue-500 hover:bg-blue-700 hover:border-gray-200 text-white py-2 px-4" href="#" @click="changeTab(Ranking)">{{ $t('classement') }}</a>
        </li>
        <li class="text-center flex-1 grid table" style="display: none">
          <div id="tabSearchEngine" class="text-center table-cell align-middle block border border-white rounded py-2 px-4 text-blue-500 hover:bg-gray-200 cursor-not-allowed" style="position : relative">
            <a id="tabSearchEngineLink" class="block text-blue-500 dark:text-gray-300 cursor-not-allowed" href="#">{{ $t('moteur_de_recherche') }}</a>
            <nuxt-img id="lightComingSoon" class="block dark:hidden top-0 right-0 absolute cursor-not-allowed" src="/coming-soon-white.png" height="40px" />
            <nuxt-img style="filter: invert(1)" class="hidden dark:block top-0 right-0 absolute" src="/coming-soon-black.png" height="40px" />
          </div>
        </li>
      </ul>

      <KeepAlive>
        <component
          :is="tab"
        />
      </KeepAlive>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, markRaw, onMounted } from 'vue'
import { storeToRefs } from 'pinia'

import Ranking from '~/components/Ranking.vue'
import Comparator from '~/components/Comparator.vue'
import SearchEngine from '~/components/SearchEngine.vue'
import { useGetSites } from '~/composables'
import { useSiteStore } from '~~/stores/sites'

const siteStore = useSiteStore()
const { sites, isSiteListLoaded } = storeToRefs(siteStore)
const tab = ref(null)

if (!isSiteListLoaded.value) {
  const { data } = await useGetSites()
  sites.value = data.value
  isSiteListLoaded.value = true
}

onMounted(() => {
  changeTab(Ranking)
})

function changeTab (comp: typeof Comparator | typeof SearchEngine | typeof Ranking) {
  if (comp === Comparator) {
    document.getElementById('tabComparator')?.setAttribute('class', 'dark:text-gray-300 text-center table-cell align-middle block border border-blue-500 rounded bg-blue-500 hover:bg-blue-700 hover:border-gray-200 text-white py-2 px-4')
    document.getElementById('tabRanking')?.setAttribute('class', 'dark:text-gray-300 text-center table-cell align-middle block border border-white rounded py-2 px-4 text-blue-500 dark:hover:bg-blue-700 hover:bg-gray-200')
    document.getElementById('tabSearchEngine')?.setAttribute('class', 'text-center table-cell align-middle block border border-white rounded py-2 px-4 text-blue-500 hover:bg-gray-200 cursor-not-allowed')
    document.getElementById('tabSearchEngineLink')?.setAttribute('class', 'cursor-not-allowed dark:text-gray-300 block text-blue-500')
    document.getElementById('lightComingSoon')?.setAttribute('style', 'filter: invert(1)')
  } else if (comp === SearchEngine) {
    document.getElementById('tabSearchEngine')?.setAttribute('class', 'dark:text-gray-300 text-center block border cursor-not-allowed border-blue-500 rounded bg-blue-500 hover:bg-blue-700 hover:border-gray-200 text-white py-2 px-4')
    document.getElementById('tabRanking')?.setAttribute('class', 'dark:text-gray-300 text-center table-cell align-middle block border border-white rounded py-2 px-4 text-blue-500 hover:bg-gray-200')
    document.getElementById('tabComparator')?.setAttribute('class', 'dark:text-gray-300 text-center table-cell align-middle block border border-white rounded py-2 px-4 text-blue-500 hover:bg-gray-200')
    document.getElementById('tabSearchEngineLink')?.setAttribute('class', 'cursor-not-allowed dark:text-gray-300 block text-white')
    document.getElementById('lightComingSoon')?.setAttribute('style', 'filter: invert(1)')
  } else if (comp === Ranking) {
    document.getElementById('tabRanking')?.setAttribute('class', 'dark:text-gray-300 text-center table-cell align-middle block border border-blue-500 rounded bg-blue-500 hover:bg-blue-700 hover:border-gray-200 text-white py-2 px-4')
    document.getElementById('tabSearchEngine')?.setAttribute('class', 'dark:text-gray-300 text-center table-cell align-middle block border border-white rounded py-2 px-4 text-blue-500 hover:bg-gray-200 cursor-not-allowed')
    document.getElementById('tabComparator')?.setAttribute('class', 'dark:text-gray-300 text-center table-cell align-middle block border border-white rounded py-2 px-4 text-blue-500 hover:bg-gray-200')
    document.getElementById('tabSearchEngineLink')?.setAttribute('class', 'cursor-not-allowed dark:text-gray-300 block text-blue-500')
    document.getElementById('lightComingSoon')?.setAttribute('style', '')
  }

  tab.value = markRaw(comp)
}

</script>

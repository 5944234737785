import { TRPCClientError } from '@trpc/client'
import type { inferRouterOutputs } from '@trpc/server'
import type { AppRouter } from '@/server/trpc/routers'

type RouterOutput = inferRouterOutputs<AppRouter>
type getTypeOutput = RouterOutput['sites']['getTypes']

type ErrorOutput = TRPCClientError<AppRouter>

export default function useGetTypes () {
  const { $client } = useNuxtApp()
  return useAsyncData<getTypeOutput, ErrorOutput>(() => $client.sites.getTypes.query())
}

<template>
  <button
    id="dropdownHelperButton"
    :data-dropdown-toggle="id"
    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
    type="button"
  >
    {{ title }}
    <svg
      class="w-4 h-4 ml-2"
      aria-hidden="true"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
    </svg>
  </button>
  <!-- Dropdown menu -->
  <div
    :id="id"
    class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-60 dark:bg-gray-700 dark:divide-gray-600"
  >
    <ul class="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownHelperButton">
      <li v-for="(choice, key, index) in choices" :key="index">
        <div class="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
          <div class="flex items-center h-5">
            <input
              :id="key"
              aria-describedby="helper-checkbox-text-1"
              type="checkbox"
              :checked="choice.checked"
              value=""
              class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              @change="onCheckboxChange(key)"
            >
          </div>
          <div class="ml-2 text-sm">
            <label for="helper-checkbox-1" class="font-medium text-gray-900 dark:text-gray-300">
              <div>{{ choice.displayTitle }}</div>
              <p id="helper-checkbox-text-1" class="text-xs font-normal text-gray-500 dark:text-gray-300">
                {{ choice.displayText }}
              </p>
            </label>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  choices: {
    type: Object,
    default: null
  },
  title: {
    type: String,
    default: ''
  },
  id: {
    type: String,
    default: ''
  },
  selectedValues: {
    type: Array<string>,
    default: []
  }
})

const { choices, selectedValues } = toRefs(props)

const onCheckboxChange = (key: string) => {
  choices.value[key].checked = !choices.value[key].checked
  if (choices.value[key].checked) {
    selectedValues.value.push(key)
  } else {
    selectedValues.value.splice(selectedValues.value.indexOf(key), 1)
  }
}
</script>

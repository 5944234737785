<template>
  <div
    v-if="!isLoading"
    class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20"
  >
    <section class="bg-blue-100-dark h-50 p-8">
      <div class="container mx-auto py-8">
        <input
          v-model="searchFilter"
          class="w-full h-16 px-3 rounded mb-8 dark:bg-gray-600 dark:text-gray-300 focus:outline-none focus:shadow-outline text-xl px-8 shadow-lg"
          type="search"
          :placeholder="$t('recherche')"
        />
        <div class="grid grid-cols-12 gap-4">
          <div
            class="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2"
          >
            <Dropdown
              id="typeSite"
              :title="$t('type_de_site')"
              :choices="choicesTypesSites"
              :selected-values="selectedTypes"
            />
          </div>
          <div
            class="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2"
          >
            <Dropdown
              id="definition"
              :title="$t('type_de_qualites')"
              :choices="choicesDefinitionsSites"
              :selected-values="selectedDefinitions"
            />
          </div>
          <div
            class="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2"
          >
            <Dropdown
              id="categories"
              :title="$t('type_de_categories')"
              :choices="choicesCategoriesSites"
              :selected-values="selectedCategories"
            />
          </div>
          <div
            class="col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2"
          >
            <Dropdown
              id="originalTapes"
              :title="$t('bandes_sons_disponibles')"
              :choices="choicesOriginalTapesSites"
              :selected-values="selectedOriginalTapes"
            />
          </div>
        </div>
      </div>
      <div v-if="isObjectEmpty(filteredSites)">
        <p class="mb-2 font-semibold leading-5">
          {{ $t("aucun_site_trouve_a_partir_des_filtres_selectionnes") }}
        </p>
      </div>
      <div
        v-for="site in filteredSites"
        :key="site.id"
        class="dark:bg-gray-900 dark:text-gray-300 transform bg-white border-l-4 border-blue-400 hover:-translate-y-2"
      >
        <div class="h-full p-5 border border-l-0 rounded-r shadow-sm">
          <h6 class="mb-2 font-semibold leading-5">
            <a :href="`${site.url}`" target="_blank">{{ site.name }}</a>
          </h6>
          <p class="text-sm dark:text-gray-300 text-gray-900">
            {{
              useGetTranslatedDescription(site.translations) ||
              "Random Description"
            }}
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import { initFlowbite } from "flowbite";
import { storeToRefs } from "pinia";

import { isObjectEmpty } from "~~/utils/objects";
import { useSiteStore } from "~~/stores/sites";

const siteStore = useSiteStore();
const { sites } = siteStore;
const {
  selectedTypes,
  selectedDefinitions,
  selectedCategories,
  selectedOriginalTapes,
} = storeToRefs(siteStore);

const isLoading = ref(true);

const searchFilter: Ref<string> = ref("");

const {
  data: typesData,
  pending: typesPending,
  error: typesRequestError,
} = useGetTypes();
const {
  data: definitionsData,
  pending: definitionsPending,
  error: definitionsRequestError,
} = useGetQualities();
const {
  data: categoriesData,
  pending: categoriesPending,
  error: categoriesRequestError,
} = useGetCategories();
const {
  data: originalTapesData,
  pending: originalTapesPending,
  error: originalTapesRequestError,
} = useGetOriginalTapes();

interface Choice {
  displayTitle: string;
  displayText: string;
  checked: boolean;
}

// initialize components based on data attribute selectors
onMounted(() => {
  initFlowbite();
});

function processTranslationsData(data: any) {
  const choices: { [key: string]: Choice } = {};

  data?.value?.forEach((item: any) => {
    const translatedItem =
      item.translations.find((translation: any) =>
        navigator.language.includes(translation.language.locale)
      ) ||
      item.translations.find(
        (translation: any) => translation.language.locale === "fr"
      );

    if (translatedItem) {
      choices[item.privateLabel] = {
        displayTitle: translatedItem.publicName,
        displayText: translatedItem.description,
        checked: false,
      };
    }
  });

  return choices;
}

const choicesTypesSites = computed(() => {
  if (!typesPending.value && !typesRequestError.value) {
    return processTranslationsData(typesData);
  }

  return [];
});

const choicesDefinitionsSites = computed(() => {
  if (!definitionsPending.value && !definitionsRequestError.value) {
    return processTranslationsData(definitionsData);
  }

  return [];
});

const choicesCategoriesSites = computed(() => {
  if (!categoriesPending.value && !categoriesRequestError.value) {
    return processTranslationsData(categoriesData);
  }

  return [];
});

const choicesOriginalTapesSites = computed(() => {
  if (!originalTapesPending.value && !originalTapesRequestError.value) {
    return processTranslationsData(originalTapesData);
  }

  return [];
});

isLoading.value = false;

const filteredSites = computed(() => {
  if (
    selectedTypes.value.length === 0 &&
    selectedDefinitions.value.length === 0 &&
    selectedCategories.value.length === 0 &&
    selectedOriginalTapes.value.length === 0 &&
    !searchFilter.value
  ) {
    return sites;
  }

  return sites.filter((site) => {
    const matchesType =
      selectedTypes.value.length === 0 ||
      site.types.some((type: { type: { privateLabel: string } }) =>
        selectedTypes.value.includes(type.type.privateLabel)
      );
    const matchesDefinition =
      selectedDefinitions.value.length === 0 ||
      selectedDefinitions.value.includes(site?.quality?.quality?.privateLabel);
    const matchesCategories =
      selectedCategories.value.length === 0 ||
      site.categories.some((category: { category: { privateLabel: string } }) =>
        selectedCategories.value.includes(category.category.privateLabel)
      );
    const matchesOriginalTapes =
      selectedOriginalTapes.value.length === 0 ||
      site.originalTapes.some(
        (originalTape: { originalTape: { privateLabel: string } }) =>
          selectedOriginalTapes.value.includes(
            originalTape.originalTape.privateLabel
          )
      );

    const matchesSearch =
      !searchFilter.value ||
      site.name.toLowerCase().includes(searchFilter.value.toLowerCase()) ||
      useGetTranslatedDescription(site.translations)
        ?.toLowerCase()
        .includes(searchFilter.value.toLowerCase());

    return (
      matchesType &&
      matchesDefinition &&
      matchesCategories &&
      matchesOriginalTapes &&
      matchesSearch
    );
  });
});
</script>
